/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "everything-you-need-right-now",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#everything-you-need-right-now",
    "aria-label": "everything you need right now permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Everything you need, right now!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Rexton Emerald series hearing aids are everything you need in a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing device"), ". It’s small, powerful, and everlasting! There’s no reason to be conflicted about which aid to use. The Emerald is designed for your convenience and comfort. They connect the user to the world in ways that, until recently, have not been possible. Don’t dance with your partner, unable to hear the music. Don’t sit and watch a play that you paid good money to see and not hear the lines. There are no small parts and no bad choices when it comes to ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/",
    className: "c-md-a"
  }, "Rexton hearing products"), ". With the Emerald, you can enjoy your life without restrictions!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-remote-control",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-remote-control",
    "aria-label": "get remote control permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Remote Control"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-smart-mic.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Emerald, using Smart Mic, makes connecting to the world around you incredibly simple! I uses ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth technology"), " for instant streaming. Watch TV like you’re the only person in the room or use the Smart Direct app to control the settings on your Emerald device. Don’t have a smartphone? No problem, there’s an optional physical remote which is tiny and discreet. You shouldn’t have to be a technological wizard to work your hearing aid. This is truly a hearing device for everyone. The Emerald will connect to most Bluetooth enabled devices for your enjoyment and convenience.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Emerald now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "power-for-days",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#power-for-days",
    "aria-label": "power for days permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Power For Days"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Emerald is a small device that contains multitudes of power. It’s size 13 battery guarantees a long-lasting, high-quality audio experience. If you’re out hiking through the mountains and you take a wrong turn, you shouldn’t have to worry about running out of battery on your hearing aid. Rexton doesn’t make hearing aids for any one specific person. They make hearing aids for ALL people. Battery life is a huge issue with all wearable and personal technology. With the Emerald’s battery, they’ve made sure that you won’t ever run out of juice! So whether you’re a hiker, or you just don’t feel like plugging your hearing aid into the charger every four hours, you’re guaranteed to be satisfied. Life is too short to have to be anxious ", React.createElement(_components.a, {
    href: "/hearing-aids/batteries",
    className: "c-md-a"
  }, "about a battery"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dolby-digital-sound",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dolby-digital-sound",
    "aria-label": "dolby digital sound permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dolby Digital Sound"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/rexton-essential-emerald.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Have you seen any good TV lately? Get ready to binge watch! The Emerald is also compatible with several Rexton accessories, including the Smart Transmitter. With the Smart Transmitter, you can link your TV directly to your hearing aid to get Dolby Digital quality sound. It’s like having a movie theater in your head! Bring the theater to your living room! Are you a gamer? Do you use your PC or Mac to play games or chat with friends and family online? The Smart Transmitter is versatile and works with many additional products. You can sync it to your computer while you video chat with family or you could tune in to an intense game of Fortnite and hear your enemies coming from far away! Rexton has made the Emerald an inclusive device. The possibilities are endless!")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bring-your-family-together",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bring-your-family-together",
    "aria-label": "bring your family together permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bring Your Family Together"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With all the options, models, and accessories that Rexton has provided with the Emerald, it’s not only a powerhouse of technology but also a bridge between generations. An elderly grandparent can adjust their hearing aid with their handheld Smart Remote, while their grandchild uses the Smart Direct App to do the same. They can spend time together watching a movie through the Smart Transmitter, enjoying a theater-like atmosphere, or the youth could introduce their grandparent to the world of online gaming! The Emerald is a balance between amazing audio technology and simple, easy-to-use products."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dont-wait",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dont-wait",
    "aria-label": "dont wait permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Don’t Wait!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don’t wait to get yourself an Emerald! As always, hear.com experts are standing by to help you get the hearing aid that best suits your needs. Pricing is never an issue. Financial assistance is available and your hear.com expert will discuss all your options. This is an invaluable service, because no one should have to wade through these waters alone. Choosing a hearing aid is a major life decision and that’s why hear.com exists, to make sure you have the best and most applicable information. Click now for a 45 day risk-free trial. If you don’t like the Emerald for any reason, send it back. What are you waiting for?"), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Emerald now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
